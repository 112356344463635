<template>
  <div>
    <el-card>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="旺店通ERP" name="1"></el-tab-pane>
        <el-tab-pane label="物流公司映射" name="2"></el-tab-pane>
      </el-tabs> -->

      <div style="padding-left:50px" v-if="activeName=='1'">
        <div class="msg" v-loading="loading">
          <div class="left">
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/wangdiantong-logo.png" alt="">
            <div style="margin-left:10px">
              <div class="flex flex-align-center">
                <div class="title">旺店通绑定信息</div>
                <el-button type="text" style="margin-left:10px" v-if="form.Id" @click="enable(true,true)">修改</el-button>
              </div>
              <div style="margin-top:20px">
                <div v-if="form.Id">
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:80px">卖家账号</div>
                    <div class="font-14 color-606266">{{form.Sid}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:80px">店铺编号</div>
                    <div class="font-14 color-606266">{{form.ShopNo}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:80px">接口帐号</div>
                    <div class="font-14 color-606266">{{form.AppKey}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:80px">接口密钥</div>
                    <div class="font-14 color-606266">{{form.AppSecret}}:{{form.Salt}}</div>
                  </div>
                </div>
                <div v-else class="font-14 color-999999">启用旺店通ERP，绑定旺店通账号数据</div>
              </div>
              <div class="flex flex-align-center" style="margin-top:30px" v-if="form.IsOpen">
                <el-button @click="pushorder">立即推送订单</el-button>
                <el-button @click="synchronization">同步物流信息</el-button>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="flex flex-align-center">
              <div class="font-14 color-606266">{{form.IsOpen?'已启用':'未启用'}}</div>
              <el-button type="text" style="margin-left:10px" @click="enable(form.IsOpen,false)">{{form.IsOpen?'停用':'启用'}}</el-button>
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="font-14 color-303133" style="margin-bottom:5px">订单推送规则：</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">1.系统每10分钟主动推送订单至旺店通ERP</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">2.仅推送待发货的快递发货单</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">3.如果订单里有退款中或退款成功商品，推送至旺店通后，旺店通系统-线上留言框中将显示退款信息</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">4.若急需发货，或订单发货前产生退款，或订单修改收货地址等，可点击上方[立即推送订单]按钮，更新订单在旺店通中的数据</div>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="useShow" :title="ischange?'修改旺店通绑定信息':'启用旺店通'" width="730px" v-loading="userloading" custom-class="dialog-body-paddingTop-10">
      <div style="padding-left:14px">
        <div class="flex flex-align-center">
          <div class="font-14 color-999999">请从旺店通开放平台、旺店通管理后台获取下方配置信息。</div>
          <el-link href="https://jusnn6k8al.feishu.cn/docx/F1F2drfAxoocZBxLap9c958ynDp" target="_blank" style="color:#409eff"
           :underline="false">查看获取方法</el-link>
        </div>
        <div style="margin-top:28px">
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">卖家账号：</div>
            <el-input v-model="editform.Sid" style="width:300px" maxlength="50" placeholder="请输入旺店通卖家账号"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">店铺编号：</div>
            <el-input v-model="editform.ShopNo" style="width:300px" maxlength="50" placeholder="请输入店铺编号"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">接口帐号：</div>
            <el-input v-model="editform.AppKey" style="width:300px" maxlength="50" placeholder="请输入接口帐号"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">接口密钥：</div>
            <div class="flex flex-align-center">
              <el-input v-model="editform.AppSecret" style="width:275px" maxlength="100" placeholder="填写冒号前面的数据"></el-input>
              <span class="font-14 color-606266" style="margin:0px 5px">：</span>
              <el-input v-model="editform.Salt" style="width:275px" maxlength="100" placeholder="填写冒号后面的数据"></el-input>
            </div>
          </div>
        </div>
        <div class="flex flex-align-center flex-justify-center" style="margin-top:30px">
          <el-button @click="useShow=false">取消</el-button>
          <el-button type="primary" @click="savemsg">保存并启用</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import {
  wdtConfiginit,
  wdtConfigedit,
  expressMappinglist,
  expressMappingedit,
  expressMappingdelete,
  wdtQjborderpush,
  wdtQjbordersyncExpress
} from "@/api/goods"
import {
  commonallexpress
} from "@/api/api"
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      activeName:'1',
      form:{

      },
      loading:false,
      useShow:false,
      ischange:true,
      userloading:false,
      editform:{
        Id:0,
        IsOpen:false,
        Sid:'',
        ShopNo:'',
        AppKey:'',
        AppSecret:'',
        Salt:'',
      },
      keywords:'',
      tablelist:[],
      tableloading:false,
      pagesize:20,
      currentPage:1,
      total:0,
      rowmsg:{},
      mappingShow:false,
      maploading:false,
      ExpressList:[],
    }
  },
  mounted () {
    this.getinfo()
    this.getExpressList()
  },
  methods: {
    del(row){
      this.$confirm('你正在操作删除物流公司映射关系。删除后，旺店通内若选择该物流公司发货，物流信息将无法同步至商城。是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await expressMappingdelete({Id:row.Id})
        if(res.IsSuccess){
          this.$message.success('删除成功')
          this.gettablelist()
        }
      }).catch(()=>{})
    },
    async getExpressList(){
      try{
        let res = await commonallexpress()
        if(res.IsSuccess){
          this.ExpressList = res.Result
        }
      }finally{
        //
      }
    },
    enable(IsOpen,ischange){
      this.ischange = ischange
      if(ischange||!IsOpen){
        this.editform = JSON.parse(JSON.stringify(this.form))
        this.useShow = true
      }else{
        this.stopuse()
      }
    },
    async stopuse(){
      try{
        this.loading = true
        let res = await wdtConfigedit({WdtConfigInfo:{
          ...this.form,
          IsOpen:false,
        }})
        if(res.IsSuccess){
          this.$message.success('已停用旺店通')
          this.getinfo()
        }
      }finally{
        this.loading = false
      }
    },
    async savemsg(){
      if(!this.editform.Sid){
        this.$message.error('请输入旺店通卖家账号')
        return 
      }else if(!this.editform.ShopNo){
        this.$message.error('请输入店铺编号')
        return 
      }else if(!this.editform.AppKey){
        this.$message.error('请输入接口帐号')
        return 
      }else if(!this.editform.AppSecret||!this.editform.Salt){
        this.$message.error('请输入正确的接口密钥')
        return 
      }
      try{
        this.userloading = true
        let res = await wdtConfigedit({WdtConfigInfo:{
          ...this.editform,
          IsOpen:true
        }})
        if(res.IsSuccess){
          this.useShow = false
          this.$message.success('启用成功')
          this.getinfo()
        }
      }finally{
        this.userloading = false
      }
    },
    async getinfo(){
      try{
        this.loading = true
        let res = await wdtConfiginit()
        if(res.IsSuccess){
          this.form = res.Result
        }
      }finally{
        this.loading = false
      }
    },
    async synchronization(){
      try{
        let res = await wdtQjbordersyncExpress()
        if(res.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        //
      }
    },
    async pushorder(){
      try{
        let res = await wdtQjborderpush()
        if(res.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        //
      }
    },
  }
}
</script>

<style lang="less" scoped>
.msg{
  width: 800px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #EBEEF5;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    align-items: flex-start;
    img{
      width: 100px;height: 100px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
}
.font-14{
  font-size: 14px;
  line-height: 1.5;
}
.color-999999{
  color: #999999;
}
.color-606266{
  color: #606266;
}
.color-303133{
  color: #303133;
}
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.flex-justify-center{
  justify-content: center;
}
.dialog-label{
  font-size: 14px;
  color: #606266;
  line-height: 36px;
  margin-right: 10px;
  flex-shrink: 0;
  &::before{
    content:'*';
    color:#f56c6c;
    margin-right:4px
  }
}
.text-right{
  width: 130px;
  text-align: right;
}
</style>